<template>
    <posts-card-deck
        :no-posts-text="$t('leagues.news.index.noPostsText')"
        :posts="posts"
        @postClick="postClick"
    />
</template>

<script>
import {League} from '@/models/League';
import {Posts} from '@/models/Post';
import PostsCardDeck from '@/components/posts/CardDeck';
import Redirect from '@/library/Redirect';

export default {
    name: 'LeagueNewsIndex',
    components: {PostsCardDeck},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data() {
        return {
            posts: new Posts([], {
                routePrefix: `leagues/${this.league.slug}`,
            }),
        };
    },
    methods: {
        postClick(post) {
            Redirect.to({
                name: 'leagues.news.show',
                params: {post: post.slug},
            });
        },
    },
};
</script>
